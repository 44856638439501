import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "src/components";
import { PageLoader } from "src/components/page-loader";
import Axios from "src/services/api";
import { useReactToPrint } from "react-to-print";

function InvoiceRenderer({
  saleId,
  saleCreatedAt,
  sellerName,
  city,
  state,
  zip,
  country,
  userTaxId,
  buyerEmail,
  taxId,
  productName,
  productPrice,
  upsellPrice,
  taxPercentage,
  totalPrice,
  payWant,
}: any) {
  const htmlTemplate = `
    <!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title>Pocketsflow | Invoice ${saleId}</title>
    <style>
      .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        color: #555;
      }

      .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
      }

      .invoice-box table td {
        padding: 5px;
        vertical-align: top;
      }

      .invoice-box table tr td:nth-child(2) {
        text-align: right;
      }

      .invoice-box table tr.top table td {
        padding-bottom: 20px;
      }

      .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
      }

      .invoice-box table tr.information table td {
        padding-bottom: 40px;
      }

      .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }

      .invoice-box table tr.details td {
        padding-bottom: 20px;
      }

      .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
      }

      .invoice-box table tr.item.last td {
        border-bottom: none;
      }

      .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
      }

      @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
          width: 100%;
          display: block;
          text-align: center;
        }

        .invoice-box table tr.information table td {
          width: 100%;
          display: block;
          text-align: center;
        }
      }

      /** RTL **/
      .invoice-box.rtl {
        direction: rtl;
        font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
          sans-serif;
      }

      .invoice-box.rtl table {
        text-align: right;
      }

      .invoice-box.rtl table tr td:nth-child(2) {
        text-align: left;
      }
    </style>
  </head>

  <body>
    <div class="invoice-box">
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  <img
                    src="https://app.pocketsflow.com/pf-logo-text.png"
                    style="width: 100%; max-width: 280px"
                  />
                </td>

                <td style="text-align: right">
                  Invoice #: ${saleId}<br />
                  Created: ${saleCreatedAt}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="2">
            <table>
              <tr>
                <td>
                  ${sellerName}<br />
                  ${city}, ${state}, ${zip}<br />
                  ${country}<br />
                  Tax ID: ${userTaxId}
                </td>

                <td style="text-align: right">
                  ${buyerEmail}<br />
                  Tax ID: ${taxId}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="heading">
          <td>Item</td>

          <td style="text-align: right">Price</td>
        </tr>

        <tr class="item">
          <td>${productName}</td>

          <td style="text-align: right">$${productPrice}</td>
        </tr>
${
  payWant
    ? `<tr class="item">
          <td>Pay-what-you-want</td>

          <td style="text-align: right">Yes</td>
        </tr>`
    : ""
}

        <tr class="item">
          <td>Upsell</td>

          <td style="text-align: right">${
            upsellPrice ? `${upsellPrice}` : "N/A"
          }</td>
        </tr>

        <tr class="item last">
          <td>Tax rate:</td>

          <td style="text-align: right">${taxPercentage}</td>
        </tr>

        <tr class="total">
          <td></td>

          <td style="text-align: right">Total: $${totalPrice}</td>
        </tr>
      </table>
    </div>
  </body>
</html>

  `;

  return <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />;
}

const Invoice = () => {
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = React.useState<any>({});
  const [product, setProduct] = React.useState<any>({});
  const [upsell, setUpsell] = React.useState<any>({});
  const [review, setReview] = React.useState<any>(null);
  const [customer, setCustomer] = React.useState<any>();
  const [user, setUser] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    setLoading(true);
    Axios.get("/orders/" + id).then((response) => {
      setReview(response.data.review);
      setProduct(response.data.product);
      setOrder(response.data.sale);
      setCustomer(response.data.customer);
      setUpsell(response.data.upsellProduct);

      Axios.get("/users/userId/" + response.data.sale.userId).then((resp) => {
        setUser(resp.data);
        setLoading(false);
      });
    });
  }, []);

  if (loading || !order) {
    return <PageLoader />;
  }

  return (
    <div>
      <div
        style={{
          paddingTop: 30,
          paddingRight: 30,
          paddingBottom: 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button onClick={reactToPrintFn} text="Download invoice" />
      </div>
      <div ref={contentRef}>
        <InvoiceRenderer
          saleId={`#${order._id.toUpperCase()}`}
          saleCreatedAt={order.createdAt}
          sellerName={user.firstName + " " + user.lastName}
          city={user.city}
          state={user.state}
          zip={user.zipCode}
          country={user.country}
          userTaxId={user.taxId || "N/A"}
          buyerEmail={customer.buyerEmail}
          taxId={order.vatNumber || "N/A"}
          productName={product.name}
          productPrice={product.payWant ? product.minPrice : product.price}
          payWant={product.payWant}
          upsellPrice={upsell?.price}
          taxPercentage={
            order.taxPercentage ? `${order.taxPercentage}%` : "N/A"
          }
          totalPrice={order.grossAmount}
        />
      </div>
    </div>
  );
};

export default Invoice;
