import React from "react";
import styles from "./ApiKeys.module.scss";

const ApiKeys = () => {
  return (
    <div className={styles.emptyState}>
      <h3 className={styles.desc}>
        API is coming very soon. You will sell software, SaaS, lifetime deals,
        licenses, and more on Pocketsflow.
      </h3>
    </div>
  );
};

export default ApiKeys;
