import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthenticationGuard, PageTitle } from "src/components";
import styles from "./Developers.module.scss";
import { Webhooks } from "./routes";
import ApiKeys from "./routes/ApiKeys";

const Developers: React.FC = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const isActive = (route: string) => location.pathname === route;

  if (!user) {
    return null;
  }

  return (
    <div className={styles.profilePageContainer}>
      <PageTitle>Developers</PageTitle>
      <div className={styles.pageContainer}>
        <div className={styles.sidebar}>
          <div
            className={
              isActive("/developers") || isActive("/developers/webhooks")
                ? styles.activeItem
                : ""
            }
          >
            <Link to="/developers/webhooks">Webhooks</Link>
          </div>
          <div
            className={
              isActive("/developers/api-keys") ? styles.activeItem : ""
            }
          >
            <Link to="/developers/api-keys">API Keys</Link>
          </div>
        </div>
        <div className={styles.content}>
          <Routes>
            {/* Redirect to webhooks */}
            <Route
              path="/"
              element={<Navigate to="/developers/webhooks" replace />}
            />
            <Route
              path="/webhooks"
              element={<AuthenticationGuard component={Webhooks} />}
            />
            <Route
              path="/api-keys"
              element={<AuthenticationGuard component={ApiKeys} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Developers;
