import React, { useEffect, useRef, useState } from "react";

import styles from "./Orders.module.scss";
import { Card, EmptyState, PageLayout, PageTitle } from "src/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Count, Countries } from "../Customers/Customers";
import "../Dashboard/Dashboard.scss";
import { IoChevronDownOutline } from "react-icons/io5";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Gravatar from "src/components/Gravatar";

const columns = [
  "ID",
  "Product",
  "Customer",
  "Tax %",
  "Transaction fee",
  "Total paid",
  "Created at",
];

const Orders = () => {
  const navigate = useNavigate();
  const [state, setState] = useState([
    {
      startDate: new Date(0),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openDate, setOpenDate] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [countries, setCountries] = useState<Countries>({});
  const [firstTime, setFirstTime] = useState(true);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenDate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!firstTime) return;
    setFirstTime(false);
    Axios.post("/orders", {
      startDate: state[0]?.startDate?.toISOString(),
      endDate: state[0]?.endDate?.toISOString(),
    }).then((response) => {
      setOrders(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        toast.promise(
          Axios.post("/orders", {
            startDate: state[0]?.startDate?.toISOString(),
            endDate: state[0]?.endDate?.toISOString(),
          }).then((response) => {
            setOrders(response.data);
          }),
          {
            loading: "Loading orders...",
            success: "Orders loaded",
            error: "Error loading orders",
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (
      (state[0]?.startDate && state[0]?.endDate) ||
      (!state[0]?.startDate && !state[0]?.endDate && !firstTime)
    ) {
      fetchStats();
    }
  }, [state[0]?.startDate, state[0]?.endDate, firstTime]);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);
  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div>
            <div
              className={styles.dateToggler}
              onClick={() => setOpenDate(!openDate)}
            >
              {dayjs(state[0].startDate).format("MMM D, YYYY")} -{" "}
              {dayjs(state[0].endDate).format("MMM D, YYYY")}
              <IoChevronDownOutline
                style={{
                  marginLeft: 5,
                }}
                size={14}
              />
            </div>
            {openDate && (
              <div className={styles.datePicker} ref={ref}>
                <DateRangePicker
                  // @ts-ignore
                  onChange={(item) => setState([item.selection])}
                  // @ts-ignore
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  // @ts-ignore
                  ranges={state}
                  direction="horizontal"
                  inputRanges={[]}
                  staticRanges={[
                    {
                      label: "Today",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Yesterday",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ),
                        endDate: new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ),
                      }),
                    },
                    {
                      label: "This Week",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - new Date().getDay()
                          )
                        ),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Last Week",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - new Date().getDay() - 7
                          )
                        ),
                        endDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - new Date().getDay() - 1
                          )
                        ),
                      }),
                    },
                    {
                      label: "This Month",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1
                        ),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Last Month",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() - 1,
                          1
                        ),
                        endDate: new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          0
                        ),
                      }),
                    },
                    {
                      label: "This Year",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(new Date().getFullYear(), 0, 1),
                        endDate: new Date(),
                      }),
                    },
                    {
                      label: "Last Year",
                      isSelected: () => false,
                      range: () => ({
                        startDate: new Date(new Date().getFullYear() - 1, 0, 1),
                        endDate: new Date(new Date().getFullYear(), 0, 0),
                      }),
                    },
                    {
                      label: "All Time",
                      isSelected: () => true,
                      range: () => ({
                        startDate: new Date(0),
                        endDate: new Date(),
                      }),
                    },
                  ]}
                />
                {/* <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Select a date"
                /> */}
              </div>
            )}
          </div>
        }
      >
        Orders
      </PageTitle>
      {loading || orders?.length === 0 ? (
        <EmptyState
          loading={loading}
          title="Get your first order"
          subTitle="Create and share your products to make sales. Your orders will show up here"
        />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table className={styles.productsTable}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {orders?.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          navigate(`/orders/${order._id}`);
                        }}
                      >
                        <div className={styles.orderId}>
                          <div className={styles.ellipsis}>
                            #{order._id.toUpperCase()}
                          </div>
                          {order.isRefunded && (
                            <div className={styles.refunded}>Refunded</div>
                          )}
                        </div>
                      </td>
                      <td>
                        <Link
                          className={styles.productNameContainer}
                          to={`/products/${order.product?._id}`}
                        >
                          <img
                            src={
                              order.product?.thumbnail ||
                              "https://via.placeholder.com/600?text=Product+Image"
                            }
                          />

                          <div className={styles.ellipsis}>
                            {order.product?.name || "Untitled"}
                          </div>
                        </Link>
                      </td>
                      <td style={{ width: "5%" }}>
                        <div className={styles.ellipsis}>
                          <Link
                            className={
                              styles.productNameContainer +
                              " " +
                              styles.customerNameContainer
                            }
                            to={`/customers/${order.customer._id}`}
                          >
                            <Gravatar email={order.customer.buyerEmail} />
                            {order.customer.buyerEmail}
                          </Link>
                        </div>
                      </td>
                      <td style={{ width: "10%" }}>
                        <div className={styles.ellipsis}>
                          {order.taxPercentage
                            ? `${order.taxPercentage}%`
                            : "N/A"}
                        </div>
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => {
                          navigate(`/orders/${order._id}`);
                        }}
                      >
                        <div className={styles.ellipsis}>
                          {formatCurrency(
                            order.fee,
                            order.stripeCurrency?.toUpperCase()
                          )}
                        </div>
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => {
                          navigate(`/orders/${order._id}`);
                        }}
                      >
                        <div className={styles.ellipsis}>
                          {formatCurrency(
                            order.grossAmount,
                            order.stripeCurrency?.toUpperCase()
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className={styles.ellipsis}
                          onClick={() => {
                            navigate(`/orders/${order._id}`);
                          }}
                        >
                          {dayjs(order.createdAt).fromNow()}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Count items={orders} />
        </>
      )}
    </div>
  );
};

export default Orders;
